import React, { useState } from 'react';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Link from 'next/link';

const { Panel } = Collapse;

const FrequentlyAskedQuestions = ({ questions, classNameForBG }) => {
  const [activeElement, setActiveElement] = useState(0);

  const tabButtonItem = (image, hoverImage, text, index) => (
    <a
      className={`im-tabs__tab ${index === activeElement ? 'im-tabs__tab--active' : ''}`}
      key={index}
      onClick={() => setActiveElement(index)}
    >
      <div className="im-tabs__tab__image">
        <svg>
          <use xlinkHref={image} />
        </svg>
      </div>
      <div className="im-tabs__tab__image-hover">
        <svg>
          <use xlinkHref={hoverImage} />
        </svg>
      </div>
      <span>{text}</span>
    </a>
  );

  const accordion = (content) => (
    <Collapse expandIconPosition="end">
      {
        _.map(content, (data, index) => (
          <Panel header={data.title} key={index}>
            {data.text}
          </Panel>
        ))
      }
    </Collapse>
  );

  return (
    <section className={classNameForBG}>
      <h2 className="section__header__title">Sıkça Sorulan Sorular</h2>
      <div className="container">
        <div className="row">
          <div className="col-15 col-md-5 col-lg-5">
            <nav className="im-tabs im-tabs--vertical">
              {
                _.map(questions, (question, index) => (
                  tabButtonItem(
                    question.image.normal,
                    question.image.hover,
                    question.title,
                    index,
                  )
                ))
              }
            </nav>
          </div>
          <div className="col-15 col-sm-10">
            <div className="tab-content tab-content--shadow">
              <div className="tab-pane active">
                {accordion(questions[activeElement].content)}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col text-center">
            <Link href="/faq/buyers">
              <a className="im-button im-button--primary">Sıkça Sorulan Sorulara Git</a>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

FrequentlyAskedQuestions.propTypes = {
  questions: PropTypes.array,
  classNameForBG: PropTypes.string,
};

export default FrequentlyAskedQuestions;
